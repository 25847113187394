import React, { memo, useCallback } from 'react'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import { iconArrowLeft, iconCross } from '../../utils/icons'
import { useTranslation } from 'react-i18next'
import Header from '../Header'
import SourceLogo from '../SourceLogo/SourceLogo'

const ModalHeader = ({
  title,
  subtitle,
  logo,
  onClickGoBack,
  backRoutePath,
  backRef,
  nav,
  isMobile,
  light,
}) => {
  const { t } = useTranslation(['common'])

  const renderLogo = useCallback(
    (isMobile) => {
      if (!logo || !logo.img) return null
      const classNameDevice = isMobile ? 'hide-desktop' : 'hide-mobile'

      return (
        <div className={`col col-md-1 col-lg-1 ${classNameDevice}`}>
          <div className="modal-header__logo">
            <img
              src={logo.img}
              alt={'page logo'}
              width={logo.width}
              height={logo.height}
            />
          </div>
        </div>
      )
    },
    [logo]
  )

  const renderBackButton = useCallback(
    (isMobile) => {
      const classNameDevice = isMobile ? 'hide-desktop' : 'hide-mobile'
      return (
        <div
          className={`${nav ? '' : 'col col-md-1 col-lg-1'} ${classNameDevice}`}
        >
          <div
            role="button"
            tabIndex={0}
            onClick={onClickGoBack}
            onKeyPress={onClickGoBack}
            className="modal-header__back-button"
            title={t('common:modal:back')}
          >
            <LocalizedTransitionLink
              ref={backRef}
              exitTransitionType="slideOut"
              entryTransitionType="none"
              addTrailingSlash={false}
              to={backRoutePath}
              className="u-block"
            >
              {isMobile ? iconCross : iconArrowLeft}
            </LocalizedTransitionLink>
          </div>
        </div>
      )
    },
    [t, onClickGoBack, backRoutePath, backRef, nav]
  )

  return (
    <div className="modal-header f f-ai-center f-direction-row">
      {!nav && (
        <>
          {onClickGoBack && renderBackButton(false)}
          {renderLogo(true)}
          <div className="modal-header__infos overflow-hidden">
            {subtitle && (
              <div className="modal-header__subtitle">{subtitle}</div>
            )}
            {title && (
              <div className="modal-header__title ft-truncat">{title}</div>
            )}
          </div>
          {renderLogo(false)}
          {onClickGoBack && renderBackButton(true)}
        </>
      )}
      {!isMobile && nav && (
        <nav className="nav-modal">
          <div className="nav-modal__logo">
            <div
              role="button"
              tabIndex={0}
              onClick={onClickGoBack}
              onKeyPress={onClickGoBack}
              className="u-block modal-header__back-button"
              title={t('common:modal:back')}
            >
              <LocalizedTransitionLink
                ref={backRef}
                exitTransitionType="fadeOut"
                entryTransitionType="none"
                addTrailingSlash={false}
                to={backRoutePath}
              >
                {iconArrowLeft}
              </LocalizedTransitionLink>
            </div>
            <SourceLogo light={light} asTransitionLink={true} />
          </div>

          <ul>
            {nav.map((page) => {
              return (
                <li className="nav__item" key={page.id}>
                  <LocalizedTransitionLink to={page.slug} partiallyActive>
                    {t(`common:nav:${page.id}`)}
                  </LocalizedTransitionLink>
                </li>
              )
            })}
          </ul>
        </nav>
      )}
      {isMobile && nav && <Header />}
    </div>
  )
}

export default memo(ModalHeader)
