import React, { memo } from 'react'
import SourceLogo from '../SourceLogo/SourceLogo'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import { useTranslation } from 'react-i18next'
import Header from '../Header'

const ModalHeaderLanding = ({ light, link, nav, isMobile }) => {
  const { t } = useTranslation(['common'])
  const classNameLink = light ? `link link--black` : `link link--white`

  return (
    <div className="f f-ai-center f-direction-row ">
      {nav && !isMobile && (
        <nav className="nav-modal">
          <SourceLogo
            light={light}
            asTransitionLink={true}
            className="nav-modal__logo"
          />
          <ul className="hide-mobile">
            {nav.map((page) => {
              return (
                <li className="nav__item" key={page.id}>
                  <LocalizedTransitionLink to={page.slug} partiallyActive>
                    {t(`common:nav:${page.id}`)}
                  </LocalizedTransitionLink>
                </li>
              )
            })}
          </ul>
        </nav>
      )}
      {nav && isMobile && <Header />}
      {link && (
        <>
          <div className="f f-ai-center f-jc-space-b" style={{ width: '100%' }}>
            <SourceLogo light={light} asTransitionLink={true} />
            <LocalizedTransitionLink
              to={link.url}
              className={classNameLink}
              addTrailingSlash={false}
            >
              <span>{link.label}</span>
            </LocalizedTransitionLink>
          </div>
        </>
      )}
    </div>
  )
}

export default memo(ModalHeaderLanding)
