import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { getUrlExtension } from '../../utils/helpers/utils'

const PrismicBreakpointPicture = (props) => {
  const {
    fallbackImg = {
      url: '',
      alt: '',
      width: '',
      height: '',
    },
    fallbackColor = '',
    fallbackAlt = '',
    type = 'fixed',
    className = '',
    sources = {
      sm: null,
      md: null,
      lg: null,
      xl: null,
      all: null,
    },
    lazy,
    transition = true,
  } = props

  const breakpoints = {
    xl: 1680,
    lg: 1240,
    md: 768,
    sm: 375,
    all: 0,
  }

  const breakpointsOrder = ['xl', 'lg', 'md', 'sm', 'all']

  const $image = useRef()
  const loading = lazy ? 'lazy' : 'eager'
  const sourcesKeys = Object.keys(sources)
  const orderedSourcesKeys = sourcesKeys.sort(function (a, b) {
    return breakpointsOrder.indexOf(a) - breakpointsOrder.indexOf(b)
  })

  const [loadingStatus, setLoadingStatus] = useState(
    transition ? 'is-loading' : ''
  )

  const onLoadImg = useCallback((e) => {
    setLoadingStatus('')
  }, [])

  useEffect(() => {
    // Workaround for react on load
    if ($image.current && $image.current.complete) setLoadingStatus('loaded')
  }, [])

  if (!fallbackImg || !fallbackImg.url || !fallbackImg.url.length) {
    if (!fallbackColor) {
      return null
    }
    return (
      <div
        className={className}
        style={{
          ...(fallbackColor && fallbackColor.length
            ? { backgroundColor: fallbackColor }
            : {}),
        }}
      ></div>
    )
  }
  const imageAlt = fallbackImg.alt || fallbackAlt

  if (orderedSourcesKeys.length) {
    return (
      <picture
        className={`${className} img-loading ${loadingStatus}`}
        style={{
          ...(fallbackColor && fallbackColor.length
            ? { backgroundColor: fallbackColor }
            : {}),
        }}
      >
        {orderedSourcesKeys.map((sourceKey) => {
          const bp = breakpoints[sourceKey]

          if (!sources[sourceKey]) {
            return null
          }

          const source = sources[sourceKey][type]

          if (!source) {
            return null
          }

          const extension = getUrlExtension(source.src)
          const imgType = extension && `image/${extension}`

          return (
            <React.Fragment key={sourceKey}>
              {source.srcSetWebp && (
                <source
                  media={bp > 0 ? `(min-width: ${bp}px)` : null}
                  srcSet={source.srcSetWebp}
                  type="image/webp"
                />
              )}
              {source.srcSet && (
                <source
                  media={bp > 0 ? `(min-width: ${bp}px)` : null}
                  srcSet={source.srcSet}
                  type={imgType}
                />
              )}
            </React.Fragment>
          )
        })}

        <img
          src={fallbackImg.url}
          alt={imageAlt}
          loading={loading}
          onLoad={onLoadImg}
          ref={$image}
          width={fallbackImg.width}
          height={fallbackImg.height}
        />
      </picture>
    )
  }

  return <img src={fallbackImg.url} alt={imageAlt} loading={loading} />
}

export default memo(PrismicBreakpointPicture)
