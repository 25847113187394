import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { getUrlExtension } from '../../utils/helpers/utils'

const PrismicPicture = ({
  type = 'fluid',
  image,
  className = '',
  lazy,
  transition = true,
  onLoad,
}) => {
  const loading = lazy ? 'lazy' : 'eager'
  const [loadingStatus, setLoadingStatus] = useState(
    transition ? 'is-loading' : ''
  )
  const $image = useRef()

  const handleLoadImg = useCallback(
    (e) => {
      setLoadingStatus('')
      if (onLoad) {
        onLoad()
      }
    },
    [onLoad]
  )

  useEffect(() => {
    // Workaround for react on load
    if ($image.current && $image.current.complete) setLoadingStatus('')
  }, [])

  if (!image) {
    return <></>
  }
  if (image[type]) {
    const extension = getUrlExtension(image[type].src)
    const imgType = extension && `image/${extension}`
    return (
      <picture className={`${className} ${loadingStatus}`}>
        {image[type].srcSetWebp && (
          <source srcSet={image[type].srcSetWebp} type="image/webp" />
        )}
        {image[type].srcSet && (
          <source srcSet={image[type].srcSet} type={imgType} />
        )}
        {image[type].src && (
          <img
            src={image[type].src}
            alt={image.alt}
            loading={loading}
            onLoad={handleLoadImg}
            ref={$image}
          />
        )}
      </picture>
    )
  }
  if (image.url) {
    return (
      <img
        src={image.url}
        alt={image.alt}
        loading={loading}
        className={className}
        onLoad={handleLoadImg}
        ref={$image}
      />
    )
  }
  return <></>
}

export default memo(PrismicPicture)
