import gsap from "gsap"
import { animateModalEntry, gsapParams, setModalEntry } from "./common"

const selectors = {
  wrapper: '.tpl-project',
  title: '.tpl-project h1',
  desc: '.tpl-project__desc',
  tags: '.tpl-project__tags li',
  year: '.tpl-project__year',
  images: {
    thumbnail: '.tpl-project__thumbnail',
    cover: '.tpl-project__cover',
  },
  header: {
    subtitle: '.tpl-project .modal-header__subtitle',
    title: '.tpl-project .modal-header__title',
  }
}

const setContent = () => {
  gsap.set(selectors.tags, { ...gsapParams.fadeOut })
  gsap.set(selectors.year, { ...gsapParams.fadeOut })

  gsap.set(selectors.images.thumbnail, { ...gsapParams.fadeOut })
  gsap.set(selectors.images.cover, { ...gsapParams.fadeOut})
}


const animateContent = () => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.wrapper,
      start: "-50% center",
      once: true
    }
  })

  tl.to(selectors.tags, { ...gsapParams.fadeIn, }, .15)
  tl.to(selectors.year, { ...gsapParams.fadeIn }, .15)
  tl.to([selectors.images.thumbnail, selectors.images.cover], { ...gsapParams.fadeIn, stagger: 0.05 }, .15)

  return tl
}

const setEntry = () => {
  setModalEntry({ onlyFadeInDesc: true})
  setContent()
}

const animateEntry = () => {
  const tl = []

  tl.push(animateModalEntry(selectors.wrapper, { onlyFadeInDesc: true }))
  tl.push(animateContent())

  return tl
}

export {
  animateEntry,
  setEntry
}