import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useState,
} from 'react'

import { HistoryContext } from '../../../utils/hooks/History'

import Seo from '../SEO'
import {
  addTrailingSlash,
  breakpoints,
  getConstrastColor,
} from '../../../utils/helpers/utils'
import { black, white } from '../../../utils/constants/colors'
import ModalHeader from '../../ModalHeader/ModalHeader'
import ModalHeaderLanding from '../../ModalHeaderLanding'
import { RouteProvider } from '../../../utils/hooks/Route'
import { getServiceNavByLang } from '../../../utils/helpers/routes'

const ModalLayout = (props) => {
  const { addRouteToHistory, removeRouteFromHistory, backRoute } =
    useContext(HistoryContext)
  const {
    name,
    language,
    seo,
    children,
    title,
    subtitle,
    logo,
    headerBgColor = '#000000',
    internationalRoute,
    uri,
    landingLink,
    isLandingPage,
    serviceModal,
  } = props
  const backRef = useRef()

  const currentRoute = useMemo(() => {
    if (!props.uri) {
      return '/'
    }

    const uri = props.uri
    uri.replace('/en/', '/')
    uri.replace('/en', '/')
    return addTrailingSlash(uri)
  }, [props.uri])

  const handleCLickGoBack = useCallback(() => {
    removeRouteFromHistory()
  }, [removeRouteFromHistory])

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    addRouteToHistory({
      path: currentRoute,
      type: 'modal',
      scrollView: 0,
      name,
    })
  }, [currentRoute, addRouteToHistory, name])

  const isConstrastLight = useMemo(
    () => getConstrastColor(headerBgColor) === 'light',
    [headerBgColor]
  )
  const styleHeader = useMemo(() => {
    return {
      backgroundColor: headerBgColor,
      color: isConstrastLight ? black : white,
    }
  }, [headerBgColor, isConstrastLight])

  const containerClasses = useMemo(
    () =>
      isLandingPage || serviceModal ? `container container-wide` : 'container',
    [isLandingPage, serviceModal]
  )

  const nav = useMemo(() => getServiceNavByLang(language), [language])

  const onKeyDown = useCallback(
    (e) => {
      if (
        e.code !== 'Escape' ||
        !backRef.current ||
        !backRoute.path ||
        !backRoute.path.length
      ) {
        return
      }
      backRef.current.click()
    },
    [backRoute]
  )

  const onResize = () => {
    setIsMobile(window.innerWidth < breakpoints.md)
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  }, [onKeyDown])

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <>
      <Seo
        seo={seo}
        internationalRoute={internationalRoute}
        uri={uri}
        language={language}
      />
      <RouteProvider name={name}>
        <div className={`page page--modal tpl-${name}`}>
          <div className="page__content">
            <div className="page__back" style={styleHeader}>
              <div className={containerClasses}>
                {isLandingPage ? (
                  <ModalHeaderLanding
                    light={isConstrastLight}
                    link={landingLink}
                    nav={serviceModal ? nav : false}
                    isMobile={isMobile}
                  />
                ) : (
                  <ModalHeader
                    title={title}
                    subtitle={subtitle}
                    onClickGoBack={handleCLickGoBack}
                    backRoutePath={backRoute.path}
                    logo={logo}
                    backRef={backRef}
                    nav={serviceModal ? nav : false}
                    isMobile={isMobile}
                    light={isConstrastLight}
                  />
                )}
              </div>
            </div>
            <main className="page__body">{children}</main>
          </div>
        </div>
      </RouteProvider>
    </>
  )
}

export default ModalLayout
