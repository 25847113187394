import React, { memo } from 'react'

const ModalContentHeader = ({ title, subtitle, logo, classNameInfos }) => (
  <div className="modal-header row f f-ai-center f-direction-row pt-4 pt-md-6">
    <div className={`modal-header__infos overflow-hidden ${classNameInfos}`}>
      {subtitle && <div className="modal-header__subtitle">{subtitle}</div>}
      {title && <div className="modal-header__title ft-truncat">{title}</div>}
    </div>
    {logo && logo.img && (
      <div className={`col col-md-1 col-lg-1`}>
        <div className="modal-header__logo">
          <img
            src={logo.img}
            alt={'page logo'}
            width={logo.width}
            height={logo.height}
          />
        </div>
      </div>
    )}
  </div>
)

ModalContentHeader.defaultProps = {
  classNameInfos: 'col col-md-11 col-lg-offset-1 col-lg-11',
}
export default memo(ModalContentHeader)
